<template>
  <div v-if="order">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('order.update')"
      :title="$t('order.update')"
      :secondaryBtn="order ? true : false"
      :secondaryBtnTitle="$t('invoice.generate')"
      :ternaryBtn="order ? true : false"
      :ternaryBtnTitle="$t('invoice.delete')"
      @submitPressed="submitPressed"
      @secondaryPressed="generateInvoice"
      @ternaryPressed="deleteInvoice"
    />
    <order-form :order-obj="order" :action="action" @clearAction="clearAction" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue';
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    TitleBar,
    OrderForm
  },
  data() {
    return {
      action: null,
      order: null
    }
  },
  mixins: [ResourceUtils],
  created() {
    this.loadOrder()
  },
  methods: {
    loadOrder() {
      const id = this.$route.params.id;
      this.$Orders.getResource({ id }).then((response) => {
        this.order = response.data
        this.order.statusSelect = {
          label: this.$helper.getEnumTranslation('order_status', this.order.status, this.$i18n.locale),
          id: this.order.status
        }
        if (this.order.cancelReason) {
          this.order.cancelSelect = {
            label: this.$helper.getEnumTranslation('order_cancel_reason', this.order.cancelReason, this.$i18n.locale),
            id: this.order.cancelReason
          }
        }
        if (!this.order.shippingAddress) {
          this.order.shippingAddress = {
            streetAddress: '',
            city: '',
            postalCode: '',
            country: '',
          }
        }
        if (!this.order.billingAddress) {
          this.order.billingAddress = {
            streetAddress: '',
            city: '',
            postalCode: '',
            country: '',
          }
        }
      });
    },
    generateInvoice() {
      const name = `faktura_${this.order.id}`;
      const body = {
        name,
        resource: this.order["@id"],
        templateName: "INVOICE",
      };
      this.$Pdfs
        .getResourceByUrl({ url: `/pdfs/${name}` })
        .then((response) => {
          if (response.status === 200) {
            const link = document.createElement("a");
            link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
            link.target = "_blank";
            link.click();
          }
        })
        .catch(() => {
          this.$Pdfs.createResource({ body }).then((response) => {
            if (response.status === 201) {
              const link = document.createElement("a");
              link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
              link.target = "_blank";
              link.click();
            }
          });
        });
    },
    deleteInvoice() {
      this.deleteByUrl(
        this.$Pdfs,
        `/pdfs/faktura_${this.order.id}`,
        this.$t('invoice.deleted')
      );
    },
    clearAction() {
      if(this.action) {
        this.action = null;
        this.loadOrder();
      }
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>
